<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      :search.sync="search"
      :page.sync="page"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      @refresh-change="refreshChange"
    >
      <!-- <template slot="searchMenu">
        <el-button size="small" icon="el-icon-delete" @click="searchReset"
          >清空</el-button
        >
      </template> -->
      <template slot="menuLeft" slot-scope="scope">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增房源 </el-button>
        <!-- <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入房源名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input> -->
      </template>
      <template slot-scope="scope" slot="menu">
        <template>
          <el-button
            type="text"
            size="small"
            icon="el-icon-view"
            class="none-border"
            @click="openDislog('view', scope.row)"
            >查看
          </el-button>
        </template>
        <template v-if="scope.row.online_status == '下线'">
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            class="none-border"
            @click="openDislog('update', scope.row)"
            >编辑
          </el-button>
        </template>

        <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)"
          >删除
        </el-button>
        <template v-if="scope.row.online_status == '上线'">
          <el-button
            type="text"
            size="small"
            icon="el-icon-download"
            class="none-border"
            @click="updateOnline('2', scope.row)"
            >下线
          </el-button>
        </template>
        <template v-if="scope.row.online_status == '下线'">
          <el-button
            type="text"
            size="small"
            icon="el-icon-upload2"
            class="none-border"
            @click="updateOnline('1', scope.row)"
            >上线
          </el-button>
        </template>
        <template v-if="scope.row.contract_id">
          <el-button type="text" size="small" icon="el-icon-view" class="none-border" @click="viewRow(scope.row)"
            >查看合同
          </el-button>
        </template>
        <template>
          <el-button type="text" size="small" icon="el-icon-view" class="none-border" @click="setVr(scope.row)"
            >3D房源
          </el-button>
        </template>
      </template>
    </avue-crud>
    <el-dialog
      :visible.sync="detailDia"
      :close-on-click-modal="false"
      :with-header="false"
      :top="'8vh'"
      :body-style="{ padding: '0px' }"
      width="70%"
    >
      <div slot="title" class="header-title">
        <span class="fts-16">{{ title }}</span>
      </div>
      <div v-if="detailDia && JSON.stringify(currentHouse) == '{}'">
        <addHousingResources v-if="dialogNexttick" @close="close"></addHousingResources>
      </div>
      <div v-if="detailDia && JSON.stringify(currentHouse) != '{}'">
        <houseInfo @close="close" :currentHouse="currentHouse" :onlyRead="onlyRead"></houseInfo>
      </div>
    </el-dialog>
    <el-drawer title="合同详情" :visible.sync="drawer" size="60%" class="manageDetail" :withHeader="false">
      <el-container v-if="drawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" @back="drawerBack()" content="合同详情"></el-page-header>
        </el-header>
        <el-container style="height: calc(100% - 50px)">
          <el-main>
            <template>
              <ManageDetail :id="currentRow.id"></ManageDetail>
            </template>
          </el-main>
        </el-container>
      </el-container>
    </el-drawer>

    <el-dialog
      :visible.sync="vrDrawer"
      destroy-on-close
      :close-on-click-modal="false"
      :with-header="false"
      :top="'8vh'"
      :body-style="{ padding: '0px' }"
      width="70%"
    >
      <div slot="title" class="header-title">
        <span class="fts-16">房间区域</span>
      </div>
      <setVr :currentRow="currentRow" @closeVrDia="closeVrDia"></setVr>
    </el-dialog>
  </basic-container>
</template>

<script>
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

import { getMetaCommonList, delMetaCommon, updateMetaCommon } from "@/api/busiMode/metaCommon"
import { option } from "./option"
import { mapGetters } from "vuex"
import houseInfo from "./addHousingResources/houseInfo"
import setVr from "./addHousingResources/setVr"

import ManageDetail from "../../contract/manageDetail.vue"

import addHousingResources from "./addHousingResources/index"
export default {
  components: { ManageDetail, addHousingResources, houseInfo, setVr },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      }
    },
  },
  data() {
    return {
      query: {},
      option: option,
      loading: true,
      model: "wisdom_park.wp_housing_resources",

      data: [],
      vrDrawer: false,
      page: {
        current: 1,
        total: 10,
        pageSize: 10,
      },
      dialogNexttick: true,
      searchText: "",
      detailDia: false,
      currentHouse: {},
      title: "",
      onlyRead: false,
      search: {
        online_status: "",
        name: "",
        status: "",
      },
      drawer: false,
      currentRow: {},
    }
  },

  watch: {},
  mounted() {},

  methods: {
    refreshChange() {
      this.onLoad(this.page)
    },
    viewRow(row) {
      this.currentRow = { id: row.contract_id }
      this.drawer = true
    },
    setVr(row) {
      this.currentRow = row
      this.vrDrawer = true
    },
    closeVrDia(row) {
      this.vrDrawer = false
    },
    drawerBack() {
      this.drawer = false
    },
    searchChange(params, done) {
      this.query = params
      let wheres = []
      for (let key in params) {
        if (key == "name") {
          wheres.push({ field: key, op: "LIKE", value: params[key] })
        } else {
          wheres.push({ field: key, value: params[key] })
        }
      }
      let body = {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
      }
      this.getMetaCommonList(body)
      done()
    },

    onLoad(page, params = {}) {
      this.loading = true
      let body = {
        current: 1,
        size: 10,
        orders: [{ field: "update_time", sort: "DESC" }],
      }
      if (page) {
        body = {
          current: page.currentPage,
          size: page.pageSize,
          orders: [{ field: "update_time", sort: "DESC" }],
        }
      }
      if (this.searchText) {
        body["wheres"] = [{ field: "name", op: "LIKE", value: this.searchText }]
      }
      this.getMetaCommonList(body)
    },
    searchReset() {
      this.query = {}
      this.onLoad(this.page)
    },
    getMetaCommonList(body) {
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data
        this.page.total = data.total
        data.records.forEach((e) => {
          if (e.online_status == 1) {
            e.online_status = "上线"
          } else {
            e.online_status = "下线"
          }
          if (e.subject_type == 0) {
            e.subject_type = "房间"
          } else if (e.subject_type == 2) {
            e.subject_type = "整栋"
          }
        })
        this.data = data.records
        this.selectionClear()
        this.loading = false
      })
    },
    close(refresh) {
      this.detailDia = false
      this.currentHouse = {}

      this.onLoad(this.page)
    },
    selectionClear() {
      this.selectionList = []
      this.$refs.crud.toggleSelection()
    },
    openDislog(type, row) {
      this.detailDia = true
      this.currentHouse = {}
      if (type == "update" || type == "view") {
        if (type == "view") {
          this.onlyRead = true
          this.title = "详情"
        } else {
          this.onlyRead = false
          this.title = "编辑"
        }
        this.$nextTick(() => {
          this.currentHouse = JSON.parse(JSON.stringify(row))
        })
      }
      if (type == "add") {
        this.title = "新增"
        this.dialogNexttick = false
        this.$nextTick(() => {
          this.dialogNexttick = true
          this.currentHouse = {}
        })
      }
    },
    updateOnline(type, row) {
      this.$confirm("确定执行此操作?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.online_status = type
          let obj = {}
          Object.keys(row).forEach((ele) => {
            if (!ele.includes("$")) {
              obj[ele] = row[ele]
            }
          })
          if (obj.subject_type == "房间") {
            obj.subject_type = 0
          }
          if (obj.subject_type == "整栋") {
            obj.subject_type = 2
          }
          if (obj.online_status == "上线") {
            obj.online_status = 1
          }
          if (obj.online_status == "下线") {
            obj.online_status = 2
          }
          if (obj.status == "待租") {
            obj.status = 1
          }
          if (obj.status == "已租") {
            obj.status = 2
          }
          let o = {
            update: obj,
            primaryKeyValue: obj["id"],
            primaryKey: "id",
          }
          updateMetaCommon(this.model, o).then((res) => {
            if (res.data.code == 200) {
              this.onLoad()
            }
          })
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id"
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.query = {}
            this.onLoad(this.page)
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .avue-crud__pagination .el-pagination {
  display: inline-block !important;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}

/deep/ .el-table td .is-left {
  padding-left: 20px;
}
</style>
