var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isTab)?_c('el-tabs',{on:{"tab-click":_vm.tabsHandleClick},model:{value:(_vm.search.ACTIVENAME),callback:function ($$v) {_vm.$set(_vm.search, "ACTIVENAME", $$v)},expression:"search.ACTIVENAME"}},_vm._l((_vm.tabs),function(item){return _c('el-tab-pane',{attrs:{"label":item.dictValue,"name":item.dictKey}})}),1):_vm._e(),(_vm.init)?_c('avue-crud',{ref:"table",attrs:{"data":_vm.tableData,"page":_vm.page,"option":_vm.option,"table-loading":_vm.tableLoading,"search":_vm.search},on:{"update:page":function($event){_vm.page=$event},"update:search":function($event){_vm.search=$event},"search-change":_vm.searchChange,"sort-change":_vm.sortChange,"on-load":function($event){return _vm.onLoad()},"refresh-change":function($event){return _vm.onLoad()}},scopedSlots:_vm._u([{key:"menuLeft",fn:function({ size }){return [(_vm.permissionCheck('add', null))?_c('el-button',{attrs:{"icon":"el-icon-plus","size":size || 'small',"type":"primary"},on:{"click":function($event){return _vm.gotoForm()}}},[_vm._v("新 增")]):_vm._e()]}},{key:"menu",fn:function({ size, row }){return [(_vm.permissionCheck('view', row))?_c('el-button',{attrs:{"icon":"el-icon-view","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.gotoForm(row, 'view')}}},[_vm._v("查看")]):_vm._e(),(_vm.exportView && _vm.permissionCheck('export', row))?_c('el-button',{attrs:{"icon":"el-icon-view","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.exportTableDetail(row)}}},[_vm._v("导出明细")]):_vm._e(),(
          _vm.permissionCheck('edit', row) &&
          (row.inventory_status == '1' || row.inventory_status == '2') &&
          row.approval_status != 2 &&
          row.approval_status != 3 &&
          (!_vm.isSeal || (_vm.isSeal && !_vm.isSeal(row)))
        )?_c('el-button',{attrs:{"icon":"el-icon-edit","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.gotoForm(row, 'edit')}}},[_vm._v("编辑")]):_vm._e(),(_vm.flowStartBtn)?[((_vm.permissionCheck('flowStart', row) && row.approval_status == 1) || row.approval_status == 4)?_c('el-button',{attrs:{"icon":"el-icon-circle-check","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.startFlow(row)}}},[_vm._v("提交审批")]):_vm._e()]:_vm._e(),(_vm.permissionCheck('flowView', row) && row.process_instance_id)?_c('el-button',{attrs:{"icon":"el-icon-circle-check","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.showTodoTask(row)}}},[_vm._v("审批记录")]):_vm._e(),_vm._t("tableMenu",null,null,{ size, row }),(_vm.permissionCheck('del', row) && row.approval_status == 1)?_c('el-button',{attrs:{"icon":"el-icon-delete","size":size || 'small',"type":"text"},on:{"click":function($event){return _vm.rowDel(row)}}},[_vm._v("删除")]):_vm._e()]}}],null,true)}):_vm._e(),_c('el-drawer',{attrs:{"visible":_vm.drawer,"size":400,"withHeader":false},on:{"update:visible":function($event){_vm.drawer=$event}}},[(_vm.drawer)?_c('el-container',{staticClass:"h-100p"},[_c('el-header',{staticClass:"bor-b",attrs:{"height":"50px"}},[_c('el-page-header',{staticClass:"lh-50",attrs:{"content":"审批记录"},on:{"back":function($event){_vm.drawer = false}}})],1),_c('el-main',{staticClass:"pad-15"},[_c('TodoTask',{attrs:{"id":_vm.currentRow.process_instance_id}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }