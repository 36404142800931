<template>
  <basic-container class="h-100p agreement" v-loading="!init">
    <el-header height="50px" class="bor-b">
      <basic-page-header @back="back" class="lh-50" title="补充协议"></basic-page-header>
    </el-header>

    <el-tabs v-model="activeName" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="item of dictData.SupplementaryType" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
    </el-tabs>
    <avue-crud :key="activeName" v-if="init" ref="table" :data="tableData" :page.sync="page" :option="_tableOption" :table-loading="tableLoading" @on-load="onLoad()" @refresh-change="onLoad()">
      <template slot="menuLeft" slot-scope="scope">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="openFormDialog(null)">新增协议 </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button type="text" size="small" icon="el-icon-view" @click="openFormDialog(scope.row, 'view')">查看 </el-button>
        <el-button v-if="scope.row.pdf_attachment_id" type="text" size="small" icon="el-icon-view" @click="viewContract(scope.row)">查看协议 </el-button>
        <el-button v-if="scope.row.word_attachment_id" type="text" size="small" icon="el-icon-download" @click="downloadContract(scope.row)">下载协议 </el-button>
        <el-button type="text" size="small" icon="el-icon-edit" v-if="scope.row.status != 2" @click="openFormDialog(scope.row, 'edit')">编辑 </el-button>

        <el-button type="text" size="small" icon="el-icon-delete" v-if="scope.row.status != 2" @click="delSubContract(scope.row.id)"> 删除 </el-button>
      </template>
    </avue-crud>
    <el-dialog :visible="dialogVisible" :top="'5vh'" :width="currentFormConfig.width" class="supplementarysForm" v-loading="dialogLoading" @close="dialogVisible = false" :close-on-click-modal="false">
      <div slot="title">
        <span class="fts-16">{{ formTitle }}</span>
        <!-- <div class="mar-l-40 dis-ib w-500 fts-14">
        <span class="mar-r-10" :class="{'text-primary': active === 1}">1.基本信息</span>
        <span class="mar-r-10" :class="{'text-primary': active === 2}">2.金额条款</span>
        <span class="mar-r-10" :class="{'text-primary': active === 3}">3.收款计划</span>
      </div> -->
      </div>
      <!-- 三方协议 -->
      <TripartiteAgreementForm ref="formComponent" v-if="dialogVisible && activeName == 1" :id="currentRow.id" :mode="currentRow.mode" :type="activeName" :contractInfo="contractInfo" :supplementaryType="dictData.SupplementaryType"></TripartiteAgreementForm>
      <!-- 终止协议 -->
      <TerminationForm ref="formComponent" v-if="dialogVisible && activeName == 2" :id="currentRow.id" :mode="currentRow.mode" :type="activeName" :contractInfo="contractInfo" :supplementaryType="dictData.SupplementaryType"></TerminationForm>
      <!-- 租赁费另签 -->
      <ZqfylqForm ref="formComponent" v-if="dialogVisible && activeName == 4" :id="currentRow.id" :mode="currentRow.mode" :type="activeName" :editMoney="editMoney" :contractInfo="contractInfo" :supplementaryType="dictData.SupplementaryType"></ZqfylqForm>
      <!-- 合同变更 -->
      <ContractReletForm ref="formComponent" v-if="dialogVisible && activeName == 3" :id="currentRow.id" :mode="currentRow.mode" :editMoney="editMoney" :type="activeName" :contractInfo="contractInfo" :supplementaryType="dictData.SupplementaryType" :roomStatus="contractRoomStatus"></ContractReletForm>
      <div slot="footer" class="text-left bor-t pad-tb-8 pad-lr-15 ov-h">
        <div class="pull-left w-50p">
          <el-button size="small" @click="dialogVisible = false" :loading="saveLoading" v-if="!editMoney">取消</el-button>
          <el-button size="small" v-if="editMoney" @click="cancelEditMoney()">取消</el-button>
        </div>
        <div class="pull-right w-50p text-right" v-if="currentRow.mode !== 'view'">
          <el-button size="small" v-if="currentFormConfig.editMoney && !editMoney" @click="setEditMoney">修改金额</el-button>
          <el-button size="small" v-if="editMoney" @click="cancelEditMoney(true)" type="primary">确认修改</el-button>
          <el-button size="small" :loading="saveLoading" type="primary" v-if="!editMoney" @click="saveForm(1)">暂存</el-button>
          <el-button size="small" :loading="saveLoading" v-if="!editMoney" type="primary" @click="saveForm(2)">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </basic-container>
</template>
<script>
import { detail as getContractDetail, supplementarySave, supplementaryUpdate, deleteSubContract } from "@/api/contract/index.js"
import { getMetaCommonList, getPicListByIds } from "@/api/busiMode/metaCommon"
import * as dictService from "@/api/system/dictbiz"
import { getUser } from "@/api/system/user"
import TripartiteAgreementForm from "./tripartiteAgreementForm.vue"
import ZqfylqForm from "./zqfylqForm.vue"
import ContractReletForm from "./contractReletForm.vue"
import TerminationForm from "./terminationForm.vue"

export default {
  components: { TripartiteAgreementForm, ZqfylqForm, ContractReletForm, TerminationForm },
  provide() {
    return {
      supplementarysIndex: this,
    }
  },
  data() {
    return {
      editMoney: false,
      saveLoading: false,
      dialogLoading: false,
      dialogVisible: false,
      formTitle: "",
      id: this.$route.params.id || null,
      contractInfo: null,
      init: false,
      tableLoading: false,
      activeName: "",
      currentRow: {
        mode: "",
        id: "",
      },
      model: "wisdom_park.wp_contract_supplementary",
      dictData: {
        SupplementaryType: [],
      },
      formConfigs: {
        1: {
          steps: [],
          width: "50%",
        },
        3: {
          steps: [],
          editMoney: true,
          width: "70%",
        },
        4: {
          steps: [],
          editMoney: true,
          width: "70%",
        },
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableOption: {
        height: "auto",
        searchMenuSpan: 24,
        searchMenuPosition: "right",
        searchShow: this.companyId ? false : true,
        searchShowBtn: this.companyId ? false : true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        border: true,
        menuWidth: 220,
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: "主合同编号",
            prop: "contract_code",
          },
          {
            label: "补充协议编号",
            prop: "supplementary_code",
          },
          // {
          //   label: "约定开始时间",
          //   prop: "start_date",
          //   width: 150,
          //   format: "yyyy年MM月dd日",
          // },
          // {
          //   label: "约定结束时间",
          //   prop: "end_date",
          //   width: 150,
          //   format: "yyyy年MM月dd日",
          // },
        ],
      },
      contractRoomStatus: [],
    }
  },
  computed: {
    currentFormConfig() {
      return (
        this.formConfigs[this.activeName] || {
          width: "50%",
          steps: [],
        }
      )
    },
    _tableOption() {
      let columnNames = {
        1: [
          {
            prop: "start_date",
            label: "约定开始时间",
          },
          {
            prop: "end_date",
            label: "约定结束时间",
          },
        ],
        2: [
          {
            prop: "sign_date",
            label: "签订时间",
          },
          {
            prop: "end_date",
            label: "提前终止时间",
          },
        ],
        3: [
          {
            prop: "start_date",
            label: "开始时间",
          },
          {
            prop: "end_date",
            label: "结束时间",
          },
        ],
        4: [
          {
            prop: "start_date",
            label: "开始时间",
          },
          {
            prop: "end_date",
            label: "结束时间",
          },
        ],
      }
      let column = []
      if (columnNames[this.activeName]) {
        columnNames[this.activeName].forEach((item) => {
          column.push({
            label: item.label,
            prop: item.prop,
            width: 150,
            format: "yyyy年MM月dd日",
          })
        })
      }
      return {
        ...this.tableOption,
        column: [...this.tableOption.column, ...column],
      }
    },
  },
  mounted() {
    dictService
      .getDictionary({
        code: "SupplementaryType",
      })
      .then((res) => {
        this.dictData.SupplementaryType = res.data.data || []
        if (this.dictData.SupplementaryType.length) {
          this.activeName = this.dictData.SupplementaryType[0].dictKey
        }
        return getContractDetail({
          id: this.id,
        }).then((res) => {
          this.contractInfo = res.data.data
          console.log(res.data)
          this.getLessorName(this.contractInfo.lessorSigned)
        })
      })
      .finally(() => {
        this.init = true
      })

    dictService.getDictionary({ code: "ContractRoomStatus" }).then(({ data }) => {
      this.contractRoomStatus = data.data
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
      // this.$emit("back");
    },
    downloadContract(row) {
      getPicListByIds([row.word_attachment_id]).then((res) => {
        if (res.data.data && res.data.data[0]) {
          let file = res.data.data[0]
          this.downFile(file.link, file.originalName)
        }
      })
    },
    viewContract(row) {
      getPicListByIds([row.pdf_attachment_id]).then((res) => {
        if (res.data.data && res.data.data[0]) {
          let file = res.data.data[0]
          window.open(`/pdfjs/web/viewer.html?file=${encodeURIComponent(file.link)}`)
        }
      })
    },
    cancelEditMoney(save) {
      Object.values(this.$refs.formComponent.form.collectionPlans).forEach((val) => {
        val.forEach((item) => {
          ;["propertyManagementFeeTotal", "amountReceivable"].forEach((name) => {
            if (!save) {
              item[name] = item[name + "_old"]
            }
            delete item[name + "_old"]
          })
        })
      })
      this.editMoney = false
    },
    setEditMoney() {
      this.$confirm("修改当期应收金额可能会导致应收总额与金额条款内的租金总额和租金预览不相等,需要手动修改租金总额,确定继续吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Object.values(this.$refs.formComponent.form.collectionPlans).forEach((val) => {
            val.forEach((item) => {
              ;["propertyManagementFeeTotal", "amountReceivable"].forEach((name) => {
                item[name + "_old"] = item[name]
              })
            })
          })
          this.editMoney = true
        })
        .catch(() => {
          this.editMoney = false
        })
    },
    initForm(form) {
      let excludes = ["contractSubjects", "attachment_ids"]
      let alias = {
        companyId: "lessee_a_company_id",
        rentersName: "lessee_a_name",
        code: "contract_code",
        id: "contract_id",
        rentersSigned: "lessee_a_sign",
        signedDate: "sign_date",
      }
      let t = { ...this.contractInfo }
      Object.keys(this.contractInfo).forEach((keyName) => {
        let name = alias[keyName] || keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
        if (!excludes.includes(keyName) && !excludes.includes(name)) {
          if (form[name] !== undefined) {
            if ((form[name] ?? "") === "") {
              form[name] = (this.contractInfo[keyName] ?? "") !== "" ? `${this.contractInfo[keyName]}` : form[name] || ""
            }
          }
        }
      })
      if (form["subjects"]) {
        form["subjects"] = (this.contractInfo.contractSubjects || []).map((item) => {
          let newItem = {
            locations: [item.buildingId, item.floorId, item.roomId],
            type: 2,
            contract_id: this.contractInfo.id,
            location_name: `${item.buildingName}${item.floorName}${item.roomName}`,
          }
          Object.keys(item).forEach((keyName) => {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            newItem[name] = item[keyName]
          })
          delete newItem["id"]
          return newItem
        })
      }
    },
    tabsHandleClick() {},
    openFormDialog(row, mode) {
      this.formTitle = `${mode !== "view" ? (row ? "修改" : "新增") : "查看"}补充协议`
      this.currentRow = {
        id: row?.id || null,
        mode: mode || "add",
        row: row,
      }
      this.dialogVisible = true
    },
    onLoad() {
      if (!this.id) {
        return
      }
      let wheres = [
        { field: "contract_id", op: "LIKE", value: this.id },
        { field: "supplementary_type", value: this.activeName },
      ]
      this.tableLoading = true
      this.tableData = []
      getMetaCommonList(this.model, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
      })
        .then((res) => {
          if (res.data && res.data.code === 200) {
            this.tableData = res.data.data.records
            this.page.total = res.data.data.total
          } else {
            this.tableData = []
            this.page.total = 0
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    saveForm(status) {
      if (!this.$refs.formComponent) {
        return
      }
      this.$refs.formComponent.save((form) => {
        if (form) {
          this.saveLoading = true
          ;(form.id ? supplementaryUpdate : supplementarySave)({
            ...form,
            status: status,
          })
            .then((res) => {
              this.responeHandle(res)
            })
            .catch((e) => {
              this.saveLoading = false
            })
        } else {
          this.$message.warning("请完善表单项")
        }
      })
    },
    responeHandle(res) {
      this.saveLoading = false
      this.dialogVisible = false
      if (res.data && res.data.code === 200) {
        this.onLoad()
        this.$message.success(res.data.msg)
      } else {
        this.$message.error(res.data.msg)
      }
    },

    /**
     * 删除草拟状态的补充协议
     * @param {补充协议ID} id
     */
    delSubContract(id) {
      this.$confirm("确定要删除该补充协议吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteSubContract(id).then((res) => {
          if (res.data.success) {
            this.$message({ message: `删除成功`, type: "success" })
            this.onLoad()
          }
        })
      })
    },

    /***
     * 获取甲方的签订人名称
     */
    getLessorName(id) {
      getUser(id).then((res) => {
        if (res.data.success) {
          console.log(res.data.data)
          this.contractInfo.lessorSigned = res.data.data.name
        } else {
          this.$message.error("获取甲方联系人失败")
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.agreement {
  /deep/.bor-b {
    padding-left: 0px;
  }
}
.supplementarysForm {
  /deep/.el-dialog__body {
    height: calc(90vh - 110px);
    overflow: auto;
    padding: 15px;
  }
  /deep/.el-dialog__footer {
    text-align: left;
    padding: 0;
  }
  /deep/.el-input-group__append,
  /deep/.el-input-group__prepend {
    padding: 0 10px;
  }
}
</style>
