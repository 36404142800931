<template>
  <div>
    <el-tabs v-if="isTab" v-model="search.ACTIVENAME" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="item of tabs" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
    </el-tabs>
    <avue-crud
      v-if="init"
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="option"
      :table-loading="tableLoading"
      :search.sync="search"
      @search-change="searchChange"
      @sort-change="sortChange"
      @on-load="onLoad()"
      @refresh-change="onLoad()"
    >
      <template slot-scope="{ size }" slot="menuLeft">
        <el-button
          v-if="permissionCheck('add', null)"
          icon="el-icon-plus"
          :size="size || 'small'"
          type="primary"
          @click="gotoForm()"
          >新 增</el-button
        >
      </template>
      <template slot-scope="{ size, row }" slot="menu">
        <el-button
          v-if="permissionCheck('view', row)"
          icon="el-icon-view"
          :size="size || 'small'"
          type="text"
          @click="gotoForm(row, 'view')"
          >查看</el-button
        >
        <el-button
          v-if="exportView && permissionCheck('export', row)"
          icon="el-icon-view"
          :size="size || 'small'"
          type="text"
          @click="exportTableDetail(row)"
          >导出明细</el-button
        >
        <el-button
          v-if="
            permissionCheck('edit', row) &&
            (row.inventory_status == '1' || row.inventory_status == '2') &&
            row.approval_status != 2 &&
            row.approval_status != 3 &&
            (!isSeal || (isSeal && !isSeal(row)))
          "
          icon="el-icon-edit"
          :size="size || 'small'"
          type="text"
          @click="gotoForm(row, 'edit')"
          >编辑</el-button
        >
        <template v-if="flowStartBtn">
          <el-button
            v-if="(permissionCheck('flowStart', row) && row.approval_status == 1) || row.approval_status == 4"
            icon="el-icon-circle-check"
            :size="size || 'small'"
            type="text"
            @click="startFlow(row)"
            >提交审批</el-button
          >
        </template>
        <el-button
          v-if="permissionCheck('flowView', row) && row.process_instance_id"
          icon="el-icon-circle-check"
          :size="size || 'small'"
          type="text"
          @click="showTodoTask(row)"
          >审批记录</el-button
        >
        <slot name="tableMenu" v-bind="{ size, row }"></slot>
        <el-button
          v-if="permissionCheck('del', row) && row.approval_status == 1"
          icon="el-icon-delete"
          :size="size || 'small'"
          type="text"
          @click="rowDel(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-drawer :visible.sync="drawer" :size="400" :withHeader="false">
      <el-container v-if="drawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" @back="drawer = false" content="审批记录"></el-page-header>
        </el-header>
        <el-main class="pad-15">
          <TodoTask :id="currentRow.process_instance_id"></TodoTask>
        </el-main>
      </el-container>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { getMetaCommonList, delMetaCommon, getMetaCommonListExport } from "@/api/busiMode/metaCommon"
import { startFlow } from "@/api/flow/flow.js"
import * as dictService from "@/api/system/dictbiz"
import TodoTask from "@/views/flow/todo-task.vue"

export default {
  components: { TodoTask },
  data() {
    return {
      init: false,
      activeName: "all",
      tableLoading: false,
      tableData: [],
      search: {
        ACTIVENAME: "all",
      },
      loading: {
        user: false,
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        height: "auto",
        searchMenuPosition: "right",
        searchLabelPosition: "right",
        searchMenuSpan: 6,
        searchLabelWidth: 100,
        border: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menuWidth: 250,
        defaultSort: {
          prop: "create_time",
          order: "descending",
        },
        ...this.tableOption,
        column: this.deepClone(this.column),
      },
      dictData: {
        goods: [],
        userList: [],
      },
      tabs: [],
      currentRow: null,
      drawer: false,
      dict: {
        flowType: [],
      },
      orders: [],
    }
  },
  props: {
    column: {
      type: Array,
      default() {
        return []
      },
    },
    model: {
      type: String,
      default: "",
    },
    exportView: {
      type: String,
      default: "",
    },
    detailTable: {
      type: String,
      default: "",
    },
    masterIdColumnName: {
      type: String,
      default: "",
    },
    actionType: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    flowStartBtn: {
      type: Boolean,
      default: true,
    },
    flowOption: {
      type: Object,
      default() {
        return null
      },
    },
    bizCode: {
      type: String,
      default: "",
    },
    actionCode: {
      type: String,
      default: "",
    },
    isTab: {
      type: Boolean,
      default: false,
    },
    tabDictCode: {
      type: String,
      default: "",
    },
    tabQueryProp: {
      type: String,
      default: "",
    },
    tableOption: {
      type: Object,
      default() {
        return {}
      },
    },
    isSeal: {
      type: Function,
      default() {
        return null
      },
    },
    permissionList: {
      type: Object,
      default() {
        return null
      },
    },
    btnShow: {
      type: Object,
      default() {
        return null
      },
    },
  },
  mounted() {
    if (this.option.defaultSort) {
      this.orders.push({
        field: this.option.defaultSort.prop,
        sort: this.option.defaultSort.order === "descending" ? "DESC" : "ASC",
      })
    }
    if (this.isTab && this.tabDictCode) {
      dictService
        .getDictionary({
          code: this.tabDictCode,
        })
        .then((res) => {
          this.tabs = [{ dictValue: "全部", dictKey: "all" }].concat(res.data.data || [])
          this.$nextTick(() => {
            this.init = true
          })
        })
        .catch((e) => {
          this.init = true
        })
    } else {
      this.init = true
    }
    if (this.flowOption && this.flowOption.dictCode) {
      dictService
        .getDictionary({
          code: "AssetCategory",
        })
        .then((res) => {
          this.dict.flowType = res.data.data || []
        })
    }
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    permissionCheck(type, row) {
      if (this.btnShow && this.btnShow[type]) {
        let display = this.btnShow[type](row)
        if (display === false) {
          return false
        }
      }
      if (this.permissionList) {
        let typeCode = `${type}Btn`
        if (this.permissionList[typeCode]) {
          let permissionCode = this.permissionList[typeCode]
          return this.vaildData(this.permission[permissionCode], false)
        }
        return true
      } else {
        return true
      }
    },
    exportTableDetail(row) {
      this.$confirm("确定要导出该条记录的明细数据吗?", "导出确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (!this.exportView) {
              done()
              return
            }
            instance.confirmButtonLoading = true
            getMetaCommonListExport(this.exportView, {
              wheres: [{ field: this.masterIdColumnName, value: row.id }],
              current: 1,
              size: 10000,
            })
              .then((res) => {
                instance.confirmButtonLoading = false
                this.downLoading = false
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let link = document.createElement("a")
                link.style.display = "none"
                link.href = url
                link.download = `${row.code}-明细.xls`
                document.body.appendChild(link)
                link.click()
                this.$message.success("操作成功")
                done()
              })
              .catch((e) => {
                instance.confirmButtonLoading = false
                done()
              })
          } else {
            done()
          }
        },
      })
    },
    showTodoTask(row) {
      this.currentRow = row
      this.drawer = true
    },
    startFlow(row) {
      this.$confirm("确定提交审批?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let businessType = this.bizCode
          let actionCode = this.actionCode
          if (this.flowOption) {
            if (this.flowOption.businessTypeKey) {
              this.dict.flowType.forEach((item) => {
                if (item.dictKey == row[this.flowOption.key]) {
                  businessType = item[this.flowOption.businessTypeKey]
                }
              })
            }
          }
          return startFlow(row.id, businessType, actionCode)
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    searchChange(form, done) {
      done()
      this.onLoad()
    },
    gotoForm(row, type) {
      type = type || "add"
      let uri = `/assets/action/${this.actionType}/${type}`
      if (type !== "add") {
        uri += "?id=" + row.id
      }
      this.$router.push(uri)
      let types = {
        add: "新增",
        view: "查看",
        edit: "编辑",
      }
      this.$breadcrumb.add({
        name: types[type] + this.title,
        path: "",
      })
    },
    tabsHandleClick() {
      this.page.currentPage = 1
      this.onLoad()
    },
    sortChange({ column, prop, order }) {
      let has = false
      // this.orders.forEach(
      //   (item , index) => {
      //     if(item.field === prop){
      //       has = true;
      //       if(!order){
      //         this.orders.splice(index , 1);
      //       }else{
      //         item.sort = order === 'descending' ? 'DESC' : 'ASC'
      //       }
      //     }
      //   }
      // );
      // if(order && has === false){
      //   this.orders.push({
      //     field: prop,
      //     sort: order === 'descending' ? 'DESC' : 'ASC'
      //   })
      // }
      this.orders = [
        {
          field: prop,
          sort: order === "descending" ? "DESC" : "ASC",
        },
      ]
      this.onLoad()
    },
    getSearch() {
      let form = this.search,
        wheres = []
      Object.keys(form).forEach((keyName) => {
        if (keyName !== "ACTIVENAME" && keyName[0] !== "$" && (form[keyName] ?? "") !== "") {
          const column = this.findObject(this.option.column, keyName)
          if (column["searchRange"]) {
            let startVal = form[keyName][0],
              endVal = form[keyName][1]
            if (startVal) {
              if (column["type"] === "date") {
                startVal = `${startVal} 00:00:00`
              }
              wheres.push({ field: keyName, value: startVal, op: "GE" })
            }
            if (endVal) {
              if (column["type"] === "date") {
                endVal = `${endVal} 23:59:59`
              }
              wheres.push({ field: keyName, value: endVal, op: "LE" })
            }
          } else if (column["searchLike"]) {
            wheres.push({ field: keyName, op: "LIKE", value: form[keyName] })
          } else {
            wheres.push({ field: keyName, value: form[keyName] })
          }
        }
      })
      return wheres
    },
    onLoad(weres) {
      let wheres = this.getSearch()
      this.tableLoading = true
      if (this.isTab && this.search.ACTIVENAME !== "all") {
        wheres.push({ field: this.tabQueryProp, value: this.search.ACTIVENAME })
      }
      this.tableData = []
      getMetaCommonList(this.model, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders,
      })
        .then((res) => {
          this.tableLoading = false
          if (res.data && res.data.code === 200) {
            this.tableData = res.data.data.records
            this.page.total = res.data.data.total
          } else {
            this.tableData = []
            this.page.total = 0
          }
        })
        .catch((e) => {
          this.tableLoading = false
        })
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done()
        this.onLoad()
        this.$message.success(res.data.msg)
      } else {
        loading && loading()
        this.$message.error(res.data.msg)
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let body = {
            pk: "id",
            ids: [row.id],
          }
          if (this.detailTable && this.masterIdColumnName) {
            body = {
              ...body,
              detailTable: this.detailTable,
              masterIdColumnName: this.masterIdColumnName,
            }
          }
          return delMetaCommon(this.model, body)
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
  },
}
</script>
