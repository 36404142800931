<template>
  <div>
    <el-col class="mar-t-10">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="pad-l-5">
        <el-tab-pane label="综合分析" name="2"></el-tab-pane>
        <!-- <el-tab-pane label="物业费分析" name="3"></el-tab-pane>
        <el-tab-pane label="能源消耗" name="4"></el-tab-pane> -->
      </el-tabs>
    </el-col>
    <el-col :span="24" class="pad-0">
      <el-col>
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>园区面积统计图</span>
            <!-- <el-select class="pull-right mar-t-5" v-model="building" :multiple-limit="3" size="small" multiple @change="qeuryStatBuild" placeholder="请选择楼宇">
                            <el-option v-for="item in buildList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select class="pull-right mar-r-5 mar-t-5" v-model="incomeType" size="small" multiple @change="qeuryStatBuild" placeholder="请选择收费类型">
                            <el-option v-for="item in incomeTypes" :key="item.value" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select> -->
          </div>

          <div style="height: 250px">
            <basic-echarts :option="chartOption1"></basic-echarts>
          </div>
        </el-card>
      </el-col>

      <!-- <el-col :span="6"> -->
      <!-- <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>招商房源统计</span>
                    </div>
                    <el-row class="mar-t-10f pad-l-25" style="height: 260px">
                        <el-col :span="24" class="pad-0 mar-b-20 mar-t-20">
                            <el-col :span="3" class="w-35 pad-0 mar-t-5f">
                                <img class="w-33 h-33" src="../../../public/assets/images/icon/list.png" alt="" />
                            </el-col>
                            <el-col :span="18" class="pad-0 pad-l-3">
                                <span class="fts-14 ftzClor mar-b-3">房源总数</span>
                                <span class="fts-16 mar-b-10" v-if="zsfyObj">
                                    {{ zsfyObj["total"] || "-" }}<small class="ftzClor"> 套</small>
                                </span>
    
                            </el-col>
                        </el-col>
                        <el-col :span="12" class="pad-0 mar-t-5">
                            <el-col :span="3" class="w-35 pad-0 mar-t-5f">
                                <img class="w-30 h-30" src="../../../public/assets/images/icon/yichuzhu.png" alt="" />
                            </el-col>
                            <el-col :span="9" class="pad-0 w-calc-35">
                                <span class="ftzClor fts-14 mar-b-3 mar-l-3">已出租</span>
                                <span class="fts-16 mar-b-10" v-if="zsfyObj">
                                    {{ zsfyObj["yz"] || "0" }} <small class="ftzClor"> 套</small>
                                </span>
                            </el-col>
                        </el-col>
                        <el-col :span="24" class="pad-0 mar-t-25">
                            <el-col :span="3" class="w-35 pad-0 mar-t-5f">
                                <img class="w-30 h-30" src="../../../public/assets/images/icon/weichuzhu.png" alt="" />
                            </el-col>
                            <el-col :span="9" class="pad-0 w-calc-35">
                                <span class="ftzClor fts-14 mar-b-3">未出租</span>
                                <span class="fts-16 mar-b-10" v-if="zsfyObj">
                                    {{ zsfyObj["dz"] || "-" }}<small class="ftzClor"> 套</small>
                                </span>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-card> -->
      <!-- <el-card class="mar-t-15 " shadow="hover" :style="{ height: '565px' }">
                    <div slot="header" class="clearfix">
                        <span>企业账单分析</span>
                        <small class="pull-right cur-p mar-l-10" :class="{ activesort: comPay == 'arrearage_amount' }" @click="changecomPay('arrearage_amount')">
                            欠费排行</small>
                        <small class="pull-right cur-p mar-l-5" :class="{ activesort: comPay == 'received_amount' }" @click="changecomPay('received_amount')">缴费排行</small>
                    </div>
                    <div class="mar-t-25f">
                        <div class="mar-t-15" v-for="(item, key) of comPays">
                            <span v-if="key == 0" :keyy="key" class="pdtitle" style="background: rgb(233, 133, 165); color: #fff">{{ key + 1 }}</span>
                            <span v-if="key == 1" :keyy="key" class="pdtitle" style="background: rgb(239, 166, 84); color: #fff">{{ key + 1 }}</span>
                            <span v-if="key == 2" :keyy="key" class="pdtitle" style="background: rgb(99, 168, 248); color: #fff">{{ key + 1 }}</span>
                            <span v-if="key > 2" :keyy="key" class="pdtitle" style="background: rgb(99, 168, 248); color: #fff">{{ key + 1 }}</span>
                            <span class="mar-l-5">{{ item.company_name }}</span>
                            <span class="text-num" v-if="comPay == 'received_amount'">{{ toqfw(item.received_amount) }}元</span>
                            <span class="text-num" v-if="comPay == 'arrearage_amount'">{{ toqfw(item.arrearage_amount) }}元</span>
                        </div>
                    </div>
                </el-card> -->
      <!-- </el-col> -->
    </el-col>
    <el-col :span="24">
      <el-card class="mar-t-15" shadow="hover">
        <div slot="header" class="clearfix">
          <span>楼宇资产分析</span>
        </div>
        <div class="mar-t-10f">
          <zjtable> </zjtable>
        </div>
      </el-card>
    </el-col>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon"
import { qeuryStatBuildMonthExpense } from "@/api/visitor/visitor"
import * as dictbizService from "@/api/system/dictbiz"

import zjtable from "./zjtable"
export default {
  components: { zjtable },

  data() {
    return {
      viewPortHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      activeName: "2",
      building: [],
      buildList: [],

      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0,
      },
      type: "",
      comPays: [],
      incomeTypes: [],
      incomeType: [],
      comPay: "received_amount",

      zsfyObj: {},
      chartOption1: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "2%",
          right: "2%",
          bottom: "3%",
          top: "11%",
          containLabel: true,
        },

        legend: {
          show: true,
          data: ["未租", "已租", "已售", "自用"],
          x: "right",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              interval: 0,
              rotate: 10,
            },
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位(m²)",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [],
      },
    }
  },
  mounted() {
    // this.getOption();
    this.getBuildList()
    this.getMetaCommonList()
    this.getMetaCommonListForComPay()
    //this.qeuryStatBuild();
    this.getMetaCommonListForArea()
    this.getDicList()
  },
  methods: {
    toqfw(num) {
      if (Number(num)) {
        let n = num
        n = this.addDou(Number(num).toFixed(2))
        return n
      } else {
        return num
      }
    },
    addDou(value) {
      var numArr = num.split(".")
      num = numArr[0]
      var result = ""
      while (num.length > 3) {
        result = "," + num.slice(-3) + result
        num = num.slice(0, num.length - 3)
      }
      if (num) {
        result = num + result
      }
      result = result + "." + numArr[1]
      return result
    },
    qeuryStatBuild() {
      let o = {}
      let b = []
      this.building.forEach((e) => {
        if (e.value != "all") {
          b.push(e)
        }
      })
      o.buildIds = b.toString()
      o.expenseTypes = this.incomeType.toString()

      qeuryStatBuildMonthExpense(o).then((res) => {
        if (res.data.code == 200) {
          this.chartOption1["xAxis"][0]["data"] = res.data.data.time
          let ydata = []
          res.data.data.data.forEach((e) => {
            if (e != 0) {
              e = Number(e) / 10000
            }
            ydata.push(e)
          })
          this.chartOption1["series"][0]["data"] = ydata
        }
      })
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "IncomeType",
        })
        .then((res) => {
          this.incomeTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            }
          })
        })
    },

    getMetaCommonListForArea() {
      getMetaCommonList(`wisdom_park.v_part_area_stat`, {
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.data.code == 200) {
          //status（状态（0.未租；1.已租；2.已售；3.自用

          const uniqueData = res.data.data.records.filter(
            (item, index, self) => index === self.findIndex((t) => t.name === item.name)
          )
          let ydata0 = []
          let ydata1 = []
          let ydata2 = []
          let ydata3 = []
          let xdata = []

          uniqueData.forEach((d) => {
            let data = []
            let name = ""
            res.data.data.records.forEach((e) => {
              if (e.name == d.name) {
                name = e.name
                data.push(e)
              }
            })
            let yd0 = 0
            let yd1 = 0
            let yd2 = 0
            let yd3 = 0
            data.forEach((e) => {
              if (e.status == 0) {
                yd0 = e.room_area
              } else if (e.status == 1) {
                yd1 = e.room_area
              } else if (e.status == 2) {
                yd2 = e.room_area
              } else if (e.status == 3) {
                yd3 = e.room_area
              }
            })
            ydata0.push(yd0)
            ydata1.push(yd1)
            ydata2.push(yd2)
            ydata3.push(yd3)
            xdata.push(d.name)
          })
          this.chartOption1["series"].push({
            name: "未租",
            type: "bar",
            stack: "总量",
            barWidth: 30,
            tooltip: {
              valueFormatter: function (value) {
                return value + "m²"
              },
            },

            data: ydata0,
          })
          this.chartOption1["series"].push({
            name: "已租",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + "m²"
              },
            },
            stack: "总量",
            data: ydata1,
          })
          this.chartOption1["series"].push({
            name: "已售",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + "m²"
              },
            },
            stack: "总量",
            data: ydata2,
          })
          this.chartOption1["series"].push({
            name: "自用",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " m²"
              },
            },
            stack: "总量",
            data: ydata3,
          })
          // this.chartOption1['series'][0]['data'] = ydata;
          this.chartOption1["xAxis"][0]["data"] = xdata
        }
      })
    },
    //招商房源统计
    getMetaCommonList() {
      getMetaCommonList(`wisdom_park_statistics.stat_housing_resources`, {
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.data.code == 200) {
          let obj = res.data.data.records
          this.zsfyObj["total"] = obj[0]["total"]
          obj.forEach((e) => {
            if (e.status == 1) {
              this.zsfyObj["dz"] = e.count_number
            }
            if (e.status == 0) {
              this.zsfyObj["yz"] = e.count_number
            }
          })
          this.zsfyObj = JSON.parse(JSON.stringify(this.zsfyObj))
        }
      })
    },
    changecomPay(val) {
      this.comPay = val
      this.getMetaCommonListForComPay()
    },
    //企业账单
    getMetaCommonListForComPay() {
      getMetaCommonList(`wisdom_park_statistics.stat_company_payment_arrears`, {
        current: 1,
        size: 10,
        orders: [{ field: this.comPay, sort: "DESC" }],
      }).then((res) => {
        if (res.data && res.data.data) {
          this.comPays = res.data.data.records
        }
      })
    },
    getBuildList() {
      getMetaCommonList("wisdom_park.wp_building", this.page).then((res) => {
        const data = res.data.data
        this.buildList = data.records
      })
    },
    handleClick(tab, event) {
      this.activeName = tab.name
    },
  },
}
</script>

<style lang="scss" scoped>
.text-num {
  text-align: right;
  display: block;
  float: right;
  margin-top: 6px;
}
.ftzClor {
  color: #999999;
}
/deep/ .el-card__body {
  padding: 15px;
}
/deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
/deep/ .el-card__header {
  padding: 8px 15px;
  line-height: 34px;
  font-size: 14px;
  border: none;
}
.pdtitle {
  line-height: 25px;
  background: #eff6ff;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.tbDiv {
  color: #fff;
  background: rgb(103, 175, 243);
  padding: 8px;
  border-radius: 50%;
}
.activesort {
  color: #1976d3;
}
</style>
